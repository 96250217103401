import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const CulinaryFolderCardFragment = gql`
  fragment CulinaryFolderCardFragment on CulinaryFolder {
    __typename
    id
    slug
    title
    mainImage {
      ...WediaImageFragment
    }
    thumbnail01 {
      ...WediaImageFragment
    }
    positiveFood
    tagsConsumptionTime {
      name
    }
    tagsTheme {
      name
    }
    articles {
      __typename
      ... on Article {
        id
        title
        slug
      }
      ... on CulinaryArticle {
        id
        title
        slug
      }
    }
  }
  ${WediaImageFragment}
`
