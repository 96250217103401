export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
  Date: any;
};

export type Article = {
  __typename?: 'Article';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  type?: Maybe<ArticleType>;
  title: Scalars['String'];
  title_homepage?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  introduction?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  template?: Maybe<ArticleTemplate>;
  show_table_of_contents: Scalars['Boolean'];
  mainImage?: Maybe<WediaImage>;
  thumbnail01?: Maybe<WediaImage>;
  thumbnail02?: Maybe<WediaImage>;
  cheeseType?: Maybe<CheeseType>;
  changed?: Maybe<Scalars['DateTime']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  nutriscore: Scalars['Boolean'];
  positiveFood: Scalars['Boolean'];
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  brand?: Maybe<Brand>;
  recipe?: Maybe<Recipe>;
  quiz?: Maybe<ArticleQuiz>;
  blocks: Array<ArticleBlockUnion>;
  tags: Array<Tag>;
  /** @deprecated Use tags instead */
  tagRegion?: Maybe<Tag>;
  /** @deprecated Use tags instead */
  tagTheme?: Maybe<Tag>;
  /** @deprecated Use tags instead */
  tagCheeseTheme?: Maybe<Tag>;
  /** @deprecated Use tags instead */
  tagHealth?: Maybe<Tag>;
  /** @deprecated Use tags instead */
  tagHealthTheme?: Maybe<Tag>;
  /** @deprecated Use tags instead */
  tagHealthType?: Maybe<Tag>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleBlock = {
  __typename?: 'ArticleBlock';
  description?: Maybe<Scalars['String']>;
  imageDesktop?: Maybe<WediaImage>;
  recipes?: Maybe<Array<Recipe>>;
};

export type ArticleBlockUnion = ArticleInfographicBlock | ArticleOneColumnBlock | ArticleTwoColumnsBlock | ArticleSliderBlock | ArticleBlock | ArticleEditorBlock;

export type ArticleEditorBlock = {
  __typename?: 'ArticleEditorBlock';
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageDesktop?: Maybe<WediaImage>;
  video?: Maybe<WediaVideo>;
  videoMobile?: Maybe<WediaVideo>;
  cheese?: Maybe<Cheese>;
  recipe?: Maybe<Recipe>;
};

export type ArticleInfographicBlock = {
  __typename?: 'ArticleInfographicBlock';
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  legend?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageDesktop?: Maybe<WediaImage>;
  imageTablet?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
};

export type ArticleOneColumnBlock = {
  __typename?: 'ArticleOneColumnBlock';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  cheese?: Maybe<Cheese>;
  imageDesktop?: Maybe<WediaImage>;
  imageTablet?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  video?: Maybe<WediaVideo>;
  videoMobile?: Maybe<WediaVideo>;
};

export enum ArticleOrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A paginated list of Article items. */
export type ArticlePaginator = {
  __typename?: 'ArticlePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Article items. */
  data: Array<Article>;
};

export type ArticleQuiz = {
  __typename?: 'ArticleQuiz';
  id: Scalars['ID'];
  title: Scalars['String'];
  picture?: Maybe<WediaImage>;
  questions: Array<ArticleQuizQuestion>;
  scores: Array<Maybe<ArticleQuizScore>>;
};

export type ArticleQuizAnswer = {
  __typename?: 'ArticleQuizAnswer';
  answer: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<WediaImage>;
  isCorrect: Scalars['Boolean'];
};

export type ArticleQuizQuestion = {
  __typename?: 'ArticleQuizQuestion';
  question: Scalars['String'];
  picture?: Maybe<WediaImage>;
  answers: Array<ArticleQuizAnswer>;
};

export type ArticleQuizScore = {
  __typename?: 'ArticleQuizScore';
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<WediaImage>;
};

export type ArticleSliderBlock = {
  __typename?: 'ArticleSliderBlock';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageDesktop?: Maybe<WediaImage>;
};

export enum ArticleTemplate {
  SliderHealthTheme = 'SLIDER_HEALTH_THEME',
  SliderCheeseTheme = 'SLIDER_CHEESE_THEME',
  OneColumnHealthTheme = 'ONE_COLUMN_HEALTH_THEME',
  OneColumnCheeseTheme = 'ONE_COLUMN_CHEESE_THEME',
  InfographicsHealthTheme = 'INFOGRAPHICS_HEALTH_THEME',
  InfographicsCheeseTheme = 'INFOGRAPHICS_CHEESE_THEME',
  TwoColumnsCheeseTheme = 'TWO_COLUMNS_CHEESE_THEME',
  TwoColumnsHealthTheme = 'TWO_COLUMNS_HEALTH_THEME',
  QuizCheeseTheme = 'QUIZ_CHEESE_THEME',
  QuizHealthTheme = 'QUIZ_HEALTH_THEME'
}

export type ArticleTwoColumnsBlock = {
  __typename?: 'ArticleTwoColumnsBlock';
  description?: Maybe<Scalars['String']>;
  imageDesktop?: Maybe<WediaImage>;
};

export enum ArticleType {
  Default = 'DEFAULT',
  Cheese = 'CHEESE',
  Health = 'HEALTH'
}

export type ArticleUnion = CulinaryArticle | Article;

export type ArticleUnionFourItemsBlock = BlockInterface & {
  __typename?: 'ArticleUnionFourItemsBlock';
  title?: Maybe<Scalars['String']>;
  articleUnions?: Maybe<Array<ArticleUnion>>;
  displayTags?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export enum ArticleUnionModel {
  Article = 'ARTICLE',
  CulinaryArticle = 'CULINARY_ARTICLE'
}

/** A paginated list of ArticleUnion items. */
export type ArticleUnionPaginator = {
  __typename?: 'ArticleUnionPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ArticleUnion items. */
  data: Array<ArticleUnion>;
};

/** Inherited from non-flexible system */
export type Block = {
  __typename?: 'Block';
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  value?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type BlockInterface = {
  order: Scalars['Int'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  samCode?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  pictureHeader?: Maybe<WediaImage>;
  pictureHeaderMobile?: Maybe<WediaImage>;
  pictureRange?: Maybe<WediaImage>;
  thumbnailRange?: Maybe<WediaImage>;
  pictureLogo?: Maybe<WediaImage>;
  pictureLogoRound?: Maybe<WediaImage>;
  soloLogo?: Maybe<WediaImage>;
  introductionTitle?: Maybe<Scalars['String']>;
  introductionText?: Maybe<Scalars['String']>;
  searchCardTitle?: Maybe<Scalars['String']>;
  searchCardDescription?: Maybe<Scalars['String']>;
  searchCardImage?: Maybe<WediaImage>;
  socialUrl?: Maybe<Scalars['String']>;
  socialPicto?: Maybe<BrandSocialPicto>;
  highlightTitle?: Maybe<Scalars['String']>;
  highlightSubtitle?: Maybe<Scalars['String']>;
  highlightBody?: Maybe<Scalars['String']>;
  highlightFormat?: Maybe<Format>;
  highlightArticles?: Maybe<Array<Article>>;
  brandRecipes?: Maybe<Array<Recipe>>;
  hit: Scalars['Int'];
  pageWrapEnabled?: Maybe<Scalars['Boolean']>;
  pageWrapBgColor?: Maybe<Scalars['String']>;
  pageWrapTitle?: Maybe<Scalars['String']>;
  pageWrapBody?: Maybe<Scalars['String']>;
  pageWrapUrl?: Maybe<Scalars['String']>;
  pageWrapImageTop?: Maybe<WediaImage>;
  pageWrapImageTopTablet?: Maybe<WediaImage>;
  pageWrapImageTopMobile?: Maybe<WediaImage>;
  pageWrapImageLeft?: Maybe<WediaImage>;
  pageWrapImageRight?: Maybe<WediaImage>;
  contacts?: Maybe<Array<BrandContact>>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  /** @deprecated Revamp doesn't include contentSlice */
  contentSlice?: Maybe<ContentSlice>;
  pushFormats: Array<BrandPushFormat>;
  articles: Array<Article>;
  cheeses: Array<Cheese>;
  recipes: Array<Recipe>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type BrandContact = {
  __typename?: 'BrandContact';
  email: Scalars['String'];
  phoneFree?: Maybe<Scalars['String']>;
  phoneUnmarked?: Maybe<Scalars['String']>;
  phoneIncreasedPricing?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

/** A paginated list of Brand items. */
export type BrandPaginator = {
  __typename?: 'BrandPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Brand items. */
  data: Array<Brand>;
};

export type BrandPushFormat = {
  __typename?: 'BrandPushFormat';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  formats?: Maybe<Array<Format>>;
};

export type BrandsBlock = BlockInterface & {
  __typename?: 'BrandsBlock';
  title?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  brands?: Maybe<Array<Brand>>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export enum BrandSocialPicto {
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
  Instagram = 'INSTAGRAM',
  Pinterest = 'PINTEREST'
}

export type Cheese = {
  __typename?: 'Cheese';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<CheeseType>;
  introduction?: Maybe<Scalars['String']>;
  isEligibleEvinLaw: Scalars['Boolean'];
  pictureHeader?: Maybe<WediaImage>;
  pictureMap?: Maybe<WediaImage>;
  pictureOrigin?: Maybe<WediaImage>;
  thumbnail01?: Maybe<WediaImage>;
  thumbnail02?: Maybe<WediaImage>;
  history?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  dyk?: Maybe<Scalars['String']>;
  taste?: Maybe<Scalars['String']>;
  tasteWineTitle?: Maybe<Scalars['String']>;
  tasteWineBody?: Maybe<Scalars['String']>;
  tasteBreadTitle?: Maybe<Scalars['String']>;
  tasteBreadBody?: Maybe<Scalars['String']>;
  health?: Maybe<Scalars['String']>;
  rangeTitle?: Maybe<Scalars['String']>;
  rangeBody?: Maybe<Scalars['String']>;
  rangeImage?: Maybe<WediaImage>;
  highlightTitle?: Maybe<Scalars['String']>;
  highlightSubtitle?: Maybe<Scalars['String']>;
  highlightBody?: Maybe<Scalars['String']>;
  highlightFormat?: Maybe<Format>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  order: Scalars['Int'];
  nutriscore: Scalars['Boolean'];
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  brand?: Maybe<Brand>;
  formats: Array<Format>;
  recipes: Array<Recipe>;
  tags: Array<Tag>;
  tagRegion?: Maybe<Tag>;
  tagMilk?: Maybe<Tag>;
  tagType?: Maybe<Tag>;
  tagIntensity?: Maybe<Tag>;
  tagsFree: Array<Tag>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum CheeseOrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A paginated list of Cheese items. */
export type CheesePaginator = {
  __typename?: 'CheesePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Cheese items. */
  data: Array<Cheese>;
};

export enum CheeseType {
  Empty = 'EMPTY',
  Aop = 'AOP',
  Igp = 'IGP',
  Other = 'OTHER',
  Savencia = 'SAVENCIA'
}

export type Color = {
  __typename?: 'Color';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ColorVariant {
  Ruby = 'Ruby',
  Yellow = 'Yellow'
}

export type Configuration = {
  __typename?: 'Configuration';
  dictionaries: ConfigurationDictionaries;
  notFound: ConfigurationNotFound;
  maintenance: ConfigurationMaintenance;
  aopImage?: Maybe<WediaImage>;
  globalPageWrapEnabled?: Maybe<Scalars['Boolean']>;
  header: ConfigurationHeader;
  footer: ConfigurationFooter;
};

export type ConfigurationDictionaries = {
  __typename?: 'ConfigurationDictionaries';
  seasons: Array<ConfigurationIdLabel>;
};

export type ConfigurationFooter = {
  __typename?: 'ConfigurationFooter';
  socialLinks: Array<ConfigurationFooterSocialLink>;
  legalLinks: Array<DefaultLink>;
  sectionsLinks: Array<ConfigurationFooterSectionLinks>;
};

export type ConfigurationFooterSectionLinks = {
  __typename?: 'ConfigurationFooterSectionLinks';
  link: DefaultLink;
  links: Array<DefaultLink>;
};

export type ConfigurationFooterSocialLink = {
  __typename?: 'ConfigurationFooterSocialLink';
  type: SocialNetwork;
  link: DefaultLink;
};

export type ConfigurationHeader = {
  __typename?: 'ConfigurationHeader';
  items: Array<ConfigurationHeaderItem>;
};

export type ConfigurationHeaderItem = {
  __typename?: 'ConfigurationHeaderItem';
  href?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  columns: ConfigurationHeaderItem2Columns;
  mobileLinks: Array<DefaultLink>;
  cards: Array<ConfigurationHeaderItem2ColumnsColumnCard>;
};

export type ConfigurationHeaderItem2Columns = {
  __typename?: 'ConfigurationHeaderItem2Columns';
  left?: Maybe<ConfigurationHeaderItem2ColumnsColumn>;
  right?: Maybe<ConfigurationHeaderItem2ColumnsColumn>;
};

export type ConfigurationHeaderItem2ColumnsColumn = {
  __typename?: 'ConfigurationHeaderItem2ColumnsColumn';
  columnLinks: Array<ConfigurationHeaderItem2ColumnsColumnColumnLinks>;
  cards: Array<ConfigurationHeaderItem2ColumnsColumnCard>;
  bottomText?: Maybe<Scalars['String']>;
  bottomTextUrl?: Maybe<Scalars['String']>;
};

export type ConfigurationHeaderItem2ColumnsColumnCard = {
  __typename?: 'ConfigurationHeaderItem2ColumnsColumnCard';
  title: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  href: Scalars['String'];
  wording: Scalars['String'];
  image?: Maybe<WediaImage>;
};

export type ConfigurationHeaderItem2ColumnsColumnColumnLinks = {
  __typename?: 'ConfigurationHeaderItem2ColumnsColumnColumnLinks';
  title?: Maybe<Scalars['String']>;
  links: Array<DefaultLink>;
  bottomText?: Maybe<Scalars['String']>;
  bottomTextUrl?: Maybe<Scalars['String']>;
};

export type ConfigurationHighco = {
  __typename?: 'ConfigurationHighco';
  maintenance: Scalars['Boolean'];
};

export type ConfigurationIdLabel = {
  __typename?: 'ConfigurationIdLabel';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type ConfigurationMaintenance = {
  __typename?: 'ConfigurationMaintenance';
  enabled: Scalars['Boolean'];
  image?: Maybe<WediaImage>;
};

export type ConfigurationNotFound = {
  __typename?: 'ConfigurationNotFound';
  image?: Maybe<WediaImage>;
};

export type ContactFormBlock = BlockInterface & {
  __typename?: 'ContactFormBlock';
  body?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type ContentSlice = {
  __typename?: 'ContentSlice';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CouponsBlock = BlockInterface & {
  __typename?: 'CouponsBlock';
  order: Scalars['Int'];
};

export type CulinaryArticle = {
  __typename?: 'CulinaryArticle';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  title_homepage?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  introduction?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  template?: Maybe<CulinaryArticleTemplate>;
  show_table_of_contents: Scalars['Boolean'];
  mainImage?: Maybe<WediaImage>;
  thumbnail01?: Maybe<WediaImage>;
  thumbnail02?: Maybe<WediaImage>;
  cheeseType?: Maybe<CheeseType>;
  changed?: Maybe<Scalars['DateTime']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  nutriscore: Scalars['Boolean'];
  positiveFood: Scalars['Boolean'];
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  brand?: Maybe<Brand>;
  recipe?: Maybe<Recipe>;
  recipes: Array<Recipe>;
  quiz?: Maybe<ArticleQuiz>;
  blocks: Array<ArticleBlockUnion>;
  tags: Array<Tag>;
  tagsConsumptionTime: Array<Tag>;
  tagsTheme: Array<Tag>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CulinaryArticleFourItemsBlock = BlockInterface & {
  __typename?: 'CulinaryArticleFourItemsBlock';
  title?: Maybe<Scalars['String']>;
  culinaryArticles?: Maybe<Array<CulinaryArticle>>;
  displayTags?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

/** A paginated list of CulinaryArticle items. */
export type CulinaryArticlePaginator = {
  __typename?: 'CulinaryArticlePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CulinaryArticle items. */
  data: Array<CulinaryArticle>;
};

export enum CulinaryArticleTemplate {
  Editor = 'EDITOR',
  Slider = 'SLIDER',
  EditorSlider = 'EDITOR_SLIDER',
  Quiz = 'QUIZ',
  OneColumn = 'ONE_COLUMN',
  TwoColumn = 'TWO_COLUMN',
  Infographics = 'INFOGRAPHICS'
}

export type CulinaryFolder = {
  __typename?: 'CulinaryFolder';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  conclusion?: Maybe<Scalars['String']>;
  cheeseType?: Maybe<CheeseType>;
  mainImage?: Maybe<WediaImage>;
  thumbnail01?: Maybe<WediaImage>;
  thumbnail02?: Maybe<WediaImage>;
  changed?: Maybe<Scalars['DateTime']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  nutriscore: Scalars['Boolean'];
  positiveFood: Scalars['Boolean'];
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  articles: Array<ArticleUnion>;
  tags: Array<Tag>;
  tagsConsumptionTime: Array<Tag>;
  tagsTheme: Array<Tag>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CulinaryFolderFourItemsBlock = BlockInterface & {
  __typename?: 'CulinaryFolderFourItemsBlock';
  title?: Maybe<Scalars['String']>;
  culinaryFolders?: Maybe<Array<CulinaryFolder>>;
  displayTags?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

/** A paginated list of CulinaryFolder items. */
export type CulinaryFolderPaginator = {
  __typename?: 'CulinaryFolderPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CulinaryFolder items. */
  data: Array<CulinaryFolder>;
};

export type CulinaryFoldersListBlock = BlockInterface & {
  __typename?: 'CulinaryFoldersListBlock';
  title?: Maybe<Scalars['String']>;
  culinaryFolders?: Maybe<Array<CulinaryFolder>>;
  order: Scalars['Int'];
};



export type DefaultLink = {
  __typename?: 'DefaultLink';
  href: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  target: LinkTarget;
  obfuscate?: Maybe<Scalars['Boolean']>;
};

export enum Device {
  Mobile = 'MOBILE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP'
}

export type DqeAutocompleteByCityIdAndAddress = {
  __typename?: 'DqeAutocompleteByCityIdAndAddress';
  number?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  addressId: Scalars['ID'];
};

export type DqeAutocompleteByZipCode = {
  __typename?: 'DqeAutocompleteByZipCode';
  city: Scalars['String'];
  cityId: Scalars['ID'];
};

export type DqeCheckEmailResponse = {
  __typename?: 'DqeCheckEmailResponse';
  id: Scalars['ID'];
  in: Scalars['Boolean'];
  error: Scalars['String'];
};

export type FaqContainerBlock = BlockInterface & {
  __typename?: 'FaqContainerBlock';
  title?: Maybe<Scalars['String']>;
  faqThemes?: Maybe<Array<FaqContainerThemeBlock>>;
  contact?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type FaqContainerThemeBlock = {
  __typename?: 'FaqContainerThemeBlock';
  title?: Maybe<Scalars['String']>;
  faqItems?: Maybe<Array<FaqContainerThemeItemBlock>>;
};

export type FaqContainerThemeItemBlock = {
  __typename?: 'FaqContainerThemeItemBlock';
  question?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type Format = {
  __typename?: 'Format';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  ean: Scalars['String'];
  picture?: Maybe<WediaImage>;
  packshot?: Maybe<WediaImage>;
  thumbnail?: Maybe<WediaImage>;
  body?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  propertyIngredients?: Maybe<Scalars['String']>;
  propertyAllergens?: Maybe<Scalars['String']>;
  propertyConservation?: Maybe<Scalars['String']>;
  physicalChemicalExcerptDryMin?: Maybe<Scalars['String']>;
  physicalChemicalExcerptDryMax?: Maybe<Scalars['String']>;
  physicalChemicalFatOnDryWeight?: Maybe<Scalars['String']>;
  physicalChemicalFatOnTotalWeight?: Maybe<Scalars['String']>;
  nutritionalInformation?: Maybe<Scalars['String']>;
  nutritionals?: Maybe<Array<FormatNutritional>>;
  positiveFood: Scalars['Boolean'];
  flipCardTitle?: Maybe<Scalars['String']>;
  flipCardBody?: Maybe<Scalars['String']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['Boolean'];
  mainBrand?: Maybe<Brand>;
  formatTrimanCategory?: Maybe<FormatTrimanCategory>;
  cheeses: Array<Cheese>;
  seo?: Maybe<Seo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FormatNutritional = {
  __typename?: 'FormatNutritional';
  servingSize?: Maybe<Scalars['String']>;
  servingSizeUnit?: Maybe<Scalars['String']>;
  servingSizeDescription?: Maybe<Scalars['String']>;
  fat?: Maybe<FormatNutritionalFood>;
  fatsat?: Maybe<FormatNutritionalFood>;
  choavl?: Maybe<FormatNutritionalFood>;
  sugar?: Maybe<FormatNutritionalFood>;
  pro?: Maybe<FormatNutritionalFood>;
  salteq?: Maybe<FormatNutritionalFood>;
  energyKj?: Maybe<FormatNutritionalFood>;
  energyKcal?: Maybe<FormatNutritionalFood>;
};

export type FormatNutritionalFood = {
  __typename?: 'FormatNutritionalFood';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['String']>;
  lessThan?: Maybe<Scalars['Boolean']>;
};

export type FormatTrimanCategory = {
  __typename?: 'FormatTrimanCategory';
  id: Scalars['ID'];
  label: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type GoodPlansListBlock = BlockInterface & {
  __typename?: 'GoodPlansListBlock';
  /** @deprecated Replaced by the plans GraphQL query */
  plans?: Maybe<Array<Plan>>;
  order: Scalars['Int'];
};

export type H1Block = BlockInterface & {
  __typename?: 'H1Block';
  title?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type HeroBannerBlock = BlockInterface & {
  __typename?: 'HeroBannerBlock';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  image?: Maybe<WediaImage>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export type HeroBannerCheesesBlock = BlockInterface & {
  __typename?: 'HeroBannerCheesesBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  order: Scalars['Int'];
};

export type HeroBannerSlideBlock = {
  __typename?: 'HeroBannerSlideBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
};

export type HeroBannerSliderBlock = BlockInterface & {
  __typename?: 'HeroBannerSliderBlock';
  slides?: Maybe<Array<Maybe<HeroBannerSlideBlock>>>;
  order: Scalars['Int'];
};

export type IframeGoodPlanBlock = BlockInterface & {
  __typename?: 'IframeGoodPlanBlock';
  /** @deprecated Whole block is deprecated */
  url?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type ImageHeaderBlock = BlockInterface & {
  __typename?: 'ImageHeaderBlock';
  image?: Maybe<WediaImage>;
  order: Scalars['Int'];
};

export enum ImagePosition {
  PictureLeft = 'pictureLeft',
  PictureRight = 'pictureRight'
}

export type IndexCheese = {
  __typename?: 'IndexCheese';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  tagRegion?: Maybe<Scalars['String']>;
  tagRegionId?: Maybe<Scalars['ID']>;
  tagMilk?: Maybe<Scalars['String']>;
  tagMilkId?: Maybe<Scalars['ID']>;
  tagType?: Maybe<Scalars['String']>;
  tagTypeId?: Maybe<Scalars['ID']>;
  tagIntensity?: Maybe<Scalars['String']>;
  tagIntensityId?: Maybe<Scalars['ID']>;
};


export enum LinkTarget {
  Self = 'SELF',
  Blank = 'BLANK'
}

export type ListArticlesBlock = BlockInterface & {
  __typename?: 'ListArticlesBlock';
  title?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['Json']>;
  withBrands?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
};

export type ListCheesesBlock = BlockInterface & {
  __typename?: 'ListCheesesBlock';
  filters?: Maybe<Scalars['Json']>;
  order: Scalars['Int'];
};

export type ListCheesesIndexBlock = BlockInterface & {
  __typename?: 'ListCheesesIndexBlock';
  filters?: Maybe<Scalars['Json']>;
  indexCheeses: Array<IndexCheese>;
  order: Scalars['Int'];
};

export type ListFormatsBlock = BlockInterface & {
  __typename?: 'ListFormatsBlock';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  formats?: Maybe<Array<Format>>;
  order: Scalars['Int'];
};

export type NutritionPortionBlock = BlockInterface & {
  __typename?: 'NutritionPortionBlock';
  title?: Maybe<Scalars['String']>;
  energy?: Maybe<Scalars['String']>;
  fat?: Maybe<Scalars['String']>;
  carbohydrates?: Maybe<Scalars['String']>;
  proteins?: Maybe<Scalars['String']>;
  fibers?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Minimum. */
  Min = 'MIN',
  /** Maximum. */
  Max = 'MAX',
  /** Sum. */
  Sum = 'SUM',
  /** Amount of items. */
  Count = 'COUNT'
}

export type OtherFullWithImage = {
  __typename?: 'OtherFullWithImage';
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['ID'];
  template: PageTemplate;
  parent?: Maybe<Page>;
  title: Scalars['String'];
  slug: Scalars['String'];
  blocks: Array<PageBlocksUnion>;
  pageWrapEnabled?: Maybe<Scalars['Boolean']>;
  pageWrapBgColor?: Maybe<Scalars['String']>;
  pageWrapTitle?: Maybe<Scalars['String']>;
  pageWrapBody?: Maybe<Scalars['String']>;
  pageWrapUrl?: Maybe<Scalars['String']>;
  pageWrapImageTop?: Maybe<WediaImage>;
  pageWrapImageTopTablet?: Maybe<WediaImage>;
  pageWrapImageTopMobile?: Maybe<WediaImage>;
  pageWrapImageLeft?: Maybe<WediaImage>;
  pageWrapImageRight?: Maybe<WediaImage>;
  seo?: Maybe<Seo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PageBlocksUnion = HeroBannerBlock | HeroBannerCheesesBlock | HeroBannerSliderBlock | RecipeTopFiveBlock | RecipeSevenItemsBlock | CulinaryArticleFourItemsBlock | CulinaryFolderFourItemsBlock | ArticleUnionFourItemsBlock | PlateFourItemsBlock | BrandsBlock | CulinaryFoldersListBlock | PlatesListBlock | H1Block | TitleBodyBlock | PushHpThumbnailBlock | PushHpFullWidthBlock | PushHpCardsBlock | PushHpRegisterBlock | VideosHpBlock | ListFormatsBlock | ListCheesesBlock | ListCheesesIndexBlock | ListArticlesBlock | CouponsBlock | SearchEngineBlock | RecipesSearchEngineBlock | ContactFormBlock | IframeGoodPlanBlock | GoodPlansListBlock | FaqContainerBlock | PositiveFoodContainerBlock;

/** A paginated list of Page items. */
export type PagePaginator = {
  __typename?: 'PagePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Page items. */
  data: Array<Page>;
};

export enum PageTemplate {
  Generic = 'GENERIC',
  Hp = 'HP',
  HpRecipes = 'HP_RECIPES',
  HpCheeses = 'HP_CHEESES',
  HpCheesesCulture = 'HP_CHEESES_CULTURE',
  HpCulinaryFolders = 'HP_CULINARY_FOLDERS',
  HpPlates = 'HP_PLATES',
  HpBrands = 'HP_BRANDS',
  ListCheeses = 'LIST_CHEESES',
  ListArticles = 'LIST_ARTICLES',
  Faq = 'FAQ',
  Search = 'SEARCH',
  Coupons = 'COUPONS',
  PositiveFood = 'POSITIVE_FOOD'
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  brand?: Maybe<Brand>;
  slug: Scalars['String'];
  picture?: Maybe<WediaImage>;
  iframe?: Maybe<Scalars['String']>;
  enableJwt?: Maybe<Scalars['Boolean']>;
  hasLightForm?: Maybe<Scalars['Boolean']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  pictureHeader?: Maybe<WediaImage>;
  titleHeader?: Maybe<Scalars['String']>;
  textHeader?: Maybe<Scalars['String']>;
  enableHeader?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Plate = {
  __typename?: 'Plate';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  mainImage?: Maybe<WediaImage>;
  thumbnail01?: Maybe<WediaImage>;
  introductionTitle?: Maybe<Scalars['String']>;
  introductionBody?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  suggestionWineTitle?: Maybe<Scalars['String']>;
  suggestionWineAuthor?: Maybe<Scalars['String']>;
  suggestionWineBody?: Maybe<Scalars['String']>;
  suggestionBreadTitle?: Maybe<Scalars['String']>;
  suggestionBreadAuthor?: Maybe<Scalars['String']>;
  suggestionBreadBody?: Maybe<Scalars['String']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  nutriscore: Scalars['Boolean'];
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  brand?: Maybe<Brand>;
  cheeses: Array<Cheese>;
  tags: Array<Tag>;
  tagsFree: Array<Tag>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PlateFourItemsBlock = BlockInterface & {
  __typename?: 'PlateFourItemsBlock';
  title?: Maybe<Scalars['String']>;
  plates?: Maybe<Array<Plate>>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

/** A paginated list of Plate items. */
export type PlatePaginator = {
  __typename?: 'PlatePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Plate items. */
  data: Array<Plate>;
};

export type PlatesListBlock = BlockInterface & {
  __typename?: 'PlatesListBlock';
  title?: Maybe<Scalars['String']>;
  plates?: Maybe<Array<Plate>>;
  order: Scalars['Int'];
};

export type PositiveFoodBlocksUnion = ImageHeaderBlock | PositiveFoodTabsBlock | PositiveFoodIntroBlock | PositiveFoodCardsBlock | PositiveFoodDynamicFormatsBlock | PositiveFoodSelectedFormatsBlock | PositiveFoodDynamicRecipesBlock | PositiveFoodDynamicArticlesBlock | PositiveFoodSelectedArticlesBlock | PositiveFoodPushBlock;

export type PositiveFoodCardBlock = BlockInterface & {
  __typename?: 'PositiveFoodCardBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  image?: Maybe<WediaImage>;
  video?: Maybe<WediaVideo>;
  order: Scalars['Int'];
};

export type PositiveFoodCardsBlock = BlockInterface & {
  __typename?: 'PositiveFoodCardsBlock';
  title?: Maybe<Scalars['String']>;
  cards?: Maybe<Array<PositiveFoodCardBlock>>;
  order: Scalars['Int'];
};

export type PositiveFoodContainerBlock = BlockInterface & {
  __typename?: 'PositiveFoodContainerBlock';
  blocks?: Maybe<Array<PositiveFoodBlocksUnion>>;
  order: Scalars['Int'];
};

export type PositiveFoodDynamicArticlesBlock = BlockInterface & {
  __typename?: 'PositiveFoodDynamicArticlesBlock';
  title?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Article>>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export type PositiveFoodDynamicFormatsBlock = BlockInterface & {
  __typename?: 'PositiveFoodDynamicFormatsBlock';
  title?: Maybe<Scalars['String']>;
  formats?: Maybe<Array<Format>>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export type PositiveFoodDynamicRecipesBlock = BlockInterface & {
  __typename?: 'PositiveFoodDynamicRecipesBlock';
  title?: Maybe<Scalars['String']>;
  recipes?: Maybe<Array<Recipe>>;
  tags?: Maybe<Array<Tag>>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type PositiveFoodIntroBlock = BlockInterface & {
  __typename?: 'PositiveFoodIntroBlock';
  title?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  highlights?: Maybe<Array<Scalars['String']>>;
  videoBlock?: Maybe<VideosCardBlock>;
  cards?: Maybe<Array<PositiveFoodCardBlock>>;
  order: Scalars['Int'];
};

export type PositiveFoodPushBlock = BlockInterface & {
  __typename?: 'PositiveFoodPushBlock';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  image?: Maybe<WediaImage>;
  order: Scalars['Int'];
};

export type PositiveFoodSelectedArticlesBlock = BlockInterface & {
  __typename?: 'PositiveFoodSelectedArticlesBlock';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Article>>;
  tags?: Maybe<Array<Tag>>;
  withButton?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
};

export type PositiveFoodSelectedFormatBlock = {
  __typename?: 'PositiveFoodSelectedFormatBlock';
  format: Format;
  flipTitle?: Maybe<Scalars['String']>;
  flipBody?: Maybe<Scalars['String']>;
};

export type PositiveFoodSelectedFormatsBlock = BlockInterface & {
  __typename?: 'PositiveFoodSelectedFormatsBlock';
  title?: Maybe<Scalars['String']>;
  formatBlocks?: Maybe<Array<Maybe<PositiveFoodSelectedFormatBlock>>>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export type PositiveFoodTabsBlock = BlockInterface & {
  __typename?: 'PositiveFoodTabsBlock';
  tabs?: Maybe<Array<DefaultLink>>;
  order: Scalars['Int'];
};

export type PushHpCardBlock = {
  __typename?: 'PushHpCardBlock';
  title?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  image?: Maybe<WediaImage>;
  backgroundColor?: Maybe<Scalars['String']>;
};

export type PushHpCardsBlock = BlockInterface & {
  __typename?: 'PushHpCardsBlock';
  title?: Maybe<Scalars['String']>;
  hpCards?: Maybe<Array<PushHpCardBlock>>;
  order: Scalars['Int'];
};

export type PushHpFullWidthBlock = BlockInterface & {
  __typename?: 'PushHpFullWidthBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  colorVariant?: Maybe<ColorVariant>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type PushHpRegisterBlock = BlockInterface & {
  __typename?: 'PushHpRegisterBlock';
  title?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type PushHpThumbnailBlock = BlockInterface & {
  __typename?: 'PushHpThumbnailBlock';
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  cardTitle?: Maybe<Scalars['String']>;
  cardSubTitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  imagePosition?: Maybe<ImagePosition>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  article?: Maybe<Article>;
  brand?: Maybe<Brand>;
  cheese?: Maybe<Cheese>;
  configuration: Configuration;
  culinaryArticle?: Maybe<CulinaryArticle>;
  culinaryFolder?: Maybe<CulinaryFolder>;
  dqeCheckEmail: DqeCheckEmailResponse;
  dqeAutocompleteByZipCode: Array<DqeAutocompleteByZipCode>;
  dqeAutocompleteByCityIdAndAddress: Array<DqeAutocompleteByCityIdAndAddress>;
  dqeAutocompleteNumbersByCityIdAndAddressId: Array<Scalars['String']>;
  format?: Maybe<Format>;
  pageBySlug?: Maybe<Page>;
  pageByTemplate?: Maybe<Page>;
  plans: Array<Plan>;
  planById?: Maybe<Plan>;
  plate?: Maybe<Plate>;
  recipe?: Maybe<Recipe>;
  redirects: Array<Redirect>;
  searchEngineAutocomplete: SearchEngineAutocompleteResponse;
  searchEngine: SearchEngineResponse;
  soloPageBySlug?: Maybe<SoloPage>;
  tag?: Maybe<Tag>;
  articles: ArticlePaginator;
  articlesUnionList: ArticleUnionPaginator;
  articlesByType: ArticlePaginator;
  articlesByTemplate: ArticlePaginator;
  brands: BrandPaginator;
  cheeses: CheesePaginator;
  culinaryArticles: CulinaryArticlePaginator;
  culinaryArticlesByTemplate: CulinaryArticlePaginator;
  culinaryFolders: CulinaryFolderPaginator;
  pages: PagePaginator;
  plates: PlatePaginator;
  recipes: RecipePaginator;
  soloPages: SoloPagePaginator;
  tags: TagPaginator;
  tagsByType: TagPaginator;
};


export type QueryArticleArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryBrandArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryCheeseArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryCulinaryArticleArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryCulinaryFolderArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryDqeCheckEmailArgs = {
  email: Scalars['String'];
};


export type QueryDqeAutocompleteByZipCodeArgs = {
  zipCode: Scalars['String'];
};


export type QueryDqeAutocompleteByCityIdAndAddressArgs = {
  cityId: Scalars['ID'];
  address: Scalars['String'];
};


export type QueryDqeAutocompleteNumbersByCityIdAndAddressIdArgs = {
  zipCode: Scalars['String'];
  cityId: Scalars['ID'];
  addressId: Scalars['ID'];
};


export type QueryFormatArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryPageBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryPageByTemplateArgs = {
  template: PageTemplate;
};


export type QueryPlanByIdArgs = {
  id: Scalars['ID'];
};


export type QueryPlateArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryRecipeArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QuerySearchEngineAutocompleteArgs = {
  q: Scalars['String'];
  type?: Maybe<SearchEngineType>;
  page?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QuerySearchEngineArgs = {
  type?: Maybe<SearchEngineType>;
  page?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['ID']>>;
  brandIds?: Maybe<Array<Scalars['ID']>>;
  cheeseIds?: Maybe<Array<Scalars['ID']>>;
  nutriscores?: Maybe<Array<Scalars['String']>>;
  seasons?: Maybe<Array<RecipeSeason>>;
  onlyPositiveFood?: Maybe<Scalars['Boolean']>;
  onlyWithVideo?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<SearchEngineOrderBy>;
};


export type QuerySoloPageBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryTagArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<TagItemType>;
  orderBy?: Maybe<TagOrderBy>;
  page?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryArticlesArgs = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryArticlesUnionListArgs = {
  onlyBranded?: Maybe<Scalars['Boolean']>;
  withoutBranded?: Maybe<Scalars['Boolean']>;
  model?: Maybe<ArticleUnionModel>;
  cheeseType?: Maybe<CheeseType>;
  brandId?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<ArticleOrderBy>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryArticlesByTypeArgs = {
  type: ArticleType;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryArticlesByTemplateArgs = {
  template: ArticleTemplate;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryBrandsArgs = {
  name?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCheesesArgs = {
  title?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
  orderBy?: Maybe<CheeseOrderBy>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCulinaryArticlesArgs = {
  title?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCulinaryArticlesByTemplateArgs = {
  template: CulinaryArticleTemplate;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCulinaryFoldersArgs = {
  title?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPagesArgs = {
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryPlatesArgs = {
  title?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryRecipesArgs = {
  title?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QuerySoloPagesArgs = {
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTagsArgs = {
  name?: Maybe<Scalars['String']>;
  first?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTagsByTypeArgs = {
  type: TagType;
  itemsIds?: Maybe<Array<Scalars['ID']>>;
  itemType?: Maybe<TagItemType>;
  page?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['ID'];
  /** @deprecated Used only during previous import */
  samId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  introduction?: Maybe<Scalars['String']>;
  cheeseType?: Maybe<CheeseType>;
  /** @deprecated Replaced by 'tag' */
  season?: Maybe<RecipeSeason>;
  servings?: Maybe<Scalars['Int']>;
  budget?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  preparationTime?: Maybe<Scalars['String']>;
  cookingTime?: Maybe<Scalars['String']>;
  mainImage?: Maybe<WediaImage>;
  thumbnail01?: Maybe<WediaImage>;
  thumbnail02?: Maybe<WediaImage>;
  thumbnail03?: Maybe<WediaImage>;
  video?: Maybe<WediaVideo>;
  videoMobile?: Maybe<WediaVideo>;
  /** @deprecated Not used anymore */
  recipay?: Maybe<Scalars['Boolean']>;
  tipsNutritionTitle?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by 'nutrition_portion_block' and 'nutrition_100g_block' */
  tipsNutritionText?: Maybe<Scalars['String']>;
  nutritionPortionBlock?: Maybe<NutritionPortionBlock>;
  nutrition100gBlock?: Maybe<NutritionPortionBlock>;
  rating: Scalars['Float'];
  ratingCount: Scalars['Int'];
  ingredients?: Maybe<Array<RecipeIngredient>>;
  directions?: Maybe<Array<Scalars['String']>>;
  outroTitle?: Maybe<Scalars['String']>;
  outroBody?: Maybe<Scalars['String']>;
  changed?: Maybe<Scalars['DateTime']>;
  publicationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  nutriscore: Scalars['Boolean'];
  nutriscoreLetter?: Maybe<Scalars['String']>;
  positiveFood: Scalars['Boolean'];
  status: Scalars['Boolean'];
  seo?: Maybe<Seo>;
  brand?: Maybe<Brand>;
  cheeses: Array<Cheese>;
  formats: Array<Format>;
  culinaryArticles: Array<CulinaryArticle>;
  tags: Array<Tag>;
  tagsConsumptionTime: Array<Tag>;
  tagsPreparationTime: Array<Tag>;
  tagsDifficulty: Array<Tag>;
  tagsCookingTime: Array<Tag>;
  tagsBalanceDiet: Array<Tag>;
  tagsBudget: Array<Tag>;
  tagsTheme: Array<Tag>;
  tagsDishExample: Array<Tag>;
  tagsMainIngredient: Array<Tag>;
  tagsSeason: Array<Tag>;
  tagsFoodHabit: Array<Tag>;
  thematicRelatedRecipes?: Maybe<Array<Recipe>>;
  brandOtherRecipes?: Maybe<Array<Recipe>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecipeIngredient = {
  __typename?: 'RecipeIngredient';
  type?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  quantity: Scalars['String'];
  product?: Maybe<Format>;
};

/** A paginated list of Recipe items. */
export type RecipePaginator = {
  __typename?: 'RecipePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Recipe items. */
  data: Array<Recipe>;
};

export enum RecipeSeason {
  Spring = 'SPRING',
  Summer = 'SUMMER',
  Autumn = 'AUTUMN',
  Winter = 'WINTER'
}

export type RecipeSevenItemsBlock = BlockInterface & {
  __typename?: 'RecipeSevenItemsBlock';
  title?: Maybe<Scalars['String']>;
  recipes?: Maybe<Array<Recipe>>;
  displayTags?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export type RecipesSearchEngineBlock = BlockInterface & {
  __typename?: 'RecipesSearchEngineBlock';
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Tag>>;
  order: Scalars['Int'];
};

export type RecipeTopFiveBlock = BlockInterface & {
  __typename?: 'RecipeTopFiveBlock';
  recipes?: Maybe<Array<Recipe>>;
  order: Scalars['Int'];
};

export type Redirect = {
  __typename?: 'Redirect';
  id: Scalars['ID'];
  source: Scalars['String'];
  destination: Scalars['String'];
  type: Scalars['Int'];
};

export type SearchEngineAutocompleteResponse = {
  __typename?: 'SearchEngineAutocompleteResponse';
  paginatorInfo: PaginatorInfo;
  data: Array<SearchEngineAutocompleteResponseItem>;
};

export type SearchEngineAutocompleteResponseItem = {
  __typename?: 'SearchEngineAutocompleteResponseItem';
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  label: Scalars['String'];
};

export type SearchEngineBlock = BlockInterface & {
  __typename?: 'SearchEngineBlock';
  filters?: Maybe<Scalars['Json']>;
  order: Scalars['Int'];
};

export enum SearchEngineOrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SearchEngineResponse = {
  __typename?: 'SearchEngineResponse';
  paginatorInfo: PaginatorInfo;
  data: Array<SearchEngineResponseUnion>;
};

export type SearchEngineResponseUnion = Article | Brand | CulinaryArticle | CulinaryFolder | Cheese | Plate | Recipe;

export enum SearchEngineType {
  Article = 'ARTICLE',
  Cheese = 'CHEESE',
  Folder = 'FOLDER',
  Recipe = 'RECIPE'
}

export type Seo = {
  __typename?: 'Seo';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  robots?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum SocialNetwork {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Twitter = 'TWITTER',
  Pinterest = 'PINTEREST'
}

export type SoloArticleUnionFourItemsBlock = BlockInterface & {
  __typename?: 'SoloArticleUnionFourItemsBlock';
  title?: Maybe<Scalars['String']>;
  articleUnions?: Maybe<Array<ArticleUnion>>;
  order: Scalars['Int'];
};

export type SoloBannerBlock = BlockInterface & {
  __typename?: 'SoloBannerBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  size?: Maybe<Scalars['String']>;
  bottomRoundedCorners?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
};

export type SoloBodyBlock = BlockInterface & {
  __typename?: 'SoloBodyBlock';
  body?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export enum SoloBodyImageRatio {
  Half = 'HALF',
  Third = 'THIRD'
}

export enum SoloBodyPosition {
  Right = 'RIGHT',
  Bottom = 'BOTTOM',
  Left = 'LEFT'
}

export type SoloBrandBlock = BlockInterface & {
  __typename?: 'SoloBrandBlock';
  products?: Maybe<Array<SoloBrandBlockProduct>>;
  order: Scalars['Int'];
};

export type SoloBrandBlockProduct = SoloBrandProduct | SoloBrandMultiProduct;

export type SoloBrandMultiProduct = {
  __typename?: 'SoloBrandMultiProduct';
  title?: Maybe<Scalars['String']>;
  products?: Maybe<Array<SoloBrandMultiProductItem>>;
  fullWidthImage?: Maybe<WediaImage>;
  fullWidthImageMobile?: Maybe<WediaImage>;
  otherFullWidthImage?: Maybe<Array<OtherFullWithImage>>;
  rangeFormats?: Maybe<Array<Format>>;
  rangeCtaBlock?: Maybe<DefaultLink>;
  rangeImage?: Maybe<WediaImage>;
};

export type SoloBrandMultiProductItem = {
  __typename?: 'SoloBrandMultiProductItem';
  image?: Maybe<WediaImage>;
  cardTitle?: Maybe<Scalars['String']>;
  cardBody?: Maybe<Scalars['String']>;
  cardCtaBlock?: Maybe<DefaultLink>;
  bodyPosition?: Maybe<SoloBodyPosition>;
};

export type SoloBrandProduct = {
  __typename?: 'SoloBrandProduct';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  cardTitle?: Maybe<Scalars['String']>;
  cardBody?: Maybe<Scalars['String']>;
  cardCtaBlock?: Maybe<DefaultLink>;
  fullWidthImage?: Maybe<WediaImage>;
  fullWidthImageMobile?: Maybe<WediaImage>;
  otherFullWidthImage?: Maybe<Array<OtherFullWithImage>>;
  rangeFormats?: Maybe<Array<Format>>;
  rangeCtaBlock?: Maybe<DefaultLink>;
  rangeImage?: Maybe<WediaImage>;
};

export type SoloContactFormBlock = BlockInterface & {
  __typename?: 'SoloContactFormBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  legal?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type SoloContactHelpBlock = BlockInterface & {
  __typename?: 'SoloContactHelpBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  order: Scalars['Int'];
};

export type SoloContactUsBlock = BlockInterface & {
  __typename?: 'SoloContactUsBlock';
  mail?: Maybe<Scalars['String']>;
  phoneService?: Maybe<Scalars['String']>;
  phoneServiceSubLabel?: Maybe<Scalars['String']>;
  phoneLocal?: Maybe<Scalars['String']>;
  phoneLocalSubLabel?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactPageUrl?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type SoloCouponsBlock = BlockInterface & {
  __typename?: 'SoloCouponsBlock';
  order: Scalars['Int'];
};

export type SoloFullWidthImageBlock = BlockInterface & {
  __typename?: 'SoloFullWidthImageBlock';
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  order: Scalars['Int'];
};

export type SoloFullWidthImageCardBlock = BlockInterface & {
  __typename?: 'SoloFullWidthImageCardBlock';
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type SoloFullWidthVideoBlock = BlockInterface & {
  __typename?: 'SoloFullWidthVideoBlock';
  video?: Maybe<WediaVideo>;
  order: Scalars['Int'];
};

export type SoloHistoryContainerBlock = BlockInterface & {
  __typename?: 'SoloHistoryContainerBlock';
  title?: Maybe<Scalars['String']>;
  years?: Maybe<Array<Scalars['String']>>;
  periodBlocks?: Maybe<Array<SoloHistoryPeriodBlock>>;
  order: Scalars['Int'];
};

export type SoloHistoryPeriodBlock = BlockInterface & {
  __typename?: 'SoloHistoryPeriodBlock';
  years?: Maybe<Scalars['String']>;
  blocks?: Maybe<Array<SoloHistoryPeriodUnion>>;
  order: Scalars['Int'];
};

export type SoloHistoryPeriodUnion = SoloHistoryYearCardBlock | SoloHistoryQuoteBlock;

export type SoloHistoryQuoteBlock = BlockInterface & {
  __typename?: 'SoloHistoryQuoteBlock';
  quote?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type SoloHistoryYearCardBlock = BlockInterface & {
  __typename?: 'SoloHistoryYearCardBlock';
  year?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  video?: Maybe<WediaVideo>;
  body?: Maybe<Scalars['String']>;
  bodyPosition?: Maybe<SoloBodyPosition>;
  order: Scalars['Int'];
};

export type SoloOtherFullWidthImageBlock = BlockInterface & {
  __typename?: 'SoloOtherFullWidthImageBlock';
  image?: Maybe<Array<OtherFullWithImage>>;
  order: Scalars['Int'];
};

export type SoloPage = {
  __typename?: 'SoloPage';
  id: Scalars['ID'];
  brandId: Scalars['ID'];
  brand: Brand;
  theme: SoloPageTheme;
  title: Scalars['String'];
  slug: Scalars['String'];
  menuTitle?: Maybe<Scalars['String']>;
  menuIcon?: Maybe<SoloPageMenuIcon>;
  menuOrder: Scalars['Int'];
  menuLinks: Array<SoloPageMenuLink>;
  headerImage?: Maybe<WediaImage>;
  headerMobileImage?: Maybe<WediaImage>;
  blocks: Array<SoloPageBlocksUnion>;
  seo?: Maybe<Seo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SoloPageBlocksUnion = SoloBannerBlock | SoloBrandBlock | SoloContactUsBlock | SoloSocialNetworksBlock | SoloCouponsBlock | SoloVideoBlock | SoloVideosBlock | SoloVideosSliderBlock | SoloFullWidthImageCardBlock | SoloFullWidthImageBlock | SoloFullWidthVideoBlock | SoloSectionTitleBlock | SoloTitleBodyCtaBlock | SoloBodyBlock | SoloTitleImageBodyBlock | SoloArticleUnionFourItemsBlock | SoloContactFormBlock | SoloContactHelpBlock | SoloRecipesBlock | SoloHistoryContainerBlock;

export enum SoloPageMenuIcon {
  Contact = 'CONTACT'
}

export type SoloPageMenuLink = {
  __typename?: 'SoloPageMenuLink';
  title: Scalars['String'];
  url: Scalars['String'];
  icon?: Maybe<SoloPageMenuIcon>;
  isActive: Scalars['Boolean'];
};

/** A paginated list of SoloPage items. */
export type SoloPagePaginator = {
  __typename?: 'SoloPagePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of SoloPage items. */
  data: Array<SoloPage>;
};

export enum SoloPageTheme {
  Basic = 'BASIC',
  Caprice = 'CAPRICE',
  Bresse = 'BRESSE',
  Giovanni = 'GIOVANNI',
  Rustique = 'RUSTIQUE',
  StAgur = 'ST_AGUR',
  StAlbray = 'ST_ALBRAY',
  Tartare = 'TARTARE',
  CoeurDeLion = 'COEUR_DE_LION',
  StMoret = 'ST_MORET',
  BritishHeritage = 'BRITISH_HERITAGE'
}

export type SoloRecipesBlock = BlockInterface & {
  __typename?: 'SoloRecipesBlock';
  topRecipe?: Maybe<Recipe>;
  firstRecipe?: Maybe<Recipe>;
  recipes?: Maybe<Array<Recipe>>;
  filters?: Maybe<Scalars['Json']>;
  order: Scalars['Int'];
};

export type SoloSectionTitleBlock = BlockInterface & {
  __typename?: 'SoloSectionTitleBlock';
  title?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type SoloSocialNetwork = {
  __typename?: 'SoloSocialNetwork';
  type: Scalars['String'];
  url: Scalars['String'];
};

export type SoloSocialNetworksBlock = BlockInterface & {
  __typename?: 'SoloSocialNetworksBlock';
  socialNetworks?: Maybe<Array<SoloSocialNetwork>>;
  order: Scalars['Int'];
};

export type SoloTitleBodyCtaBlock = BlockInterface & {
  __typename?: 'SoloTitleBodyCtaBlock';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type SoloTitleImageBodyBlock = BlockInterface & {
  __typename?: 'SoloTitleImageBodyBlock';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<WediaImage>;
  imageMobile?: Maybe<WediaImage>;
  body?: Maybe<Scalars['String']>;
  bodyPosition?: Maybe<SoloBodyPosition>;
  bodyImageRatio?: Maybe<SoloBodyImageRatio>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type SoloVideoBlock = BlockInterface & {
  __typename?: 'SoloVideoBlock';
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<WediaVideo>;
  videoPoster?: Maybe<WediaImage>;
  videoTitle?: Maybe<Scalars['String']>;
  videoDescription?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  order: Scalars['Int'];
};

export type SoloVideosBlock = BlockInterface & {
  __typename?: 'SoloVideosBlock';
  title?: Maybe<Scalars['String']>;
  videoBlocks?: Maybe<Array<SoloVideoBlock>>;
  order: Scalars['Int'];
};

export type SoloVideosSliderBlock = BlockInterface & {
  __typename?: 'SoloVideosSliderBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  videoBlocks?: Maybe<Array<SoloVideoBlock>>;
  order: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  type: TagType;
  name: Scalars['String'];
  slug: Scalars['String'];
  longName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  picture?: Maybe<WediaImage>;
  thumbnail?: Maybe<WediaImage>;
  seoBlockTitle?: Maybe<Scalars['String']>;
  seoBlockBody?: Maybe<Scalars['String']>;
  /** @deprecated Not used in current revamp cf. TagItemCount */
  hit: Scalars['Int'];
  /** @deprecated Not used in current revamp cf. TagItemCount */
  hitFolder: Scalars['Int'];
  /** @deprecated Not used in current revamp cf. TagItemCount */
  hitArticle: Scalars['Int'];
  /** @deprecated Not used in current revamp */
  voucherChildren: Scalars['Boolean'];
  /** @deprecated Not used in current revamp */
  color?: Maybe<Color>;
  items?: Maybe<TagItems>;
  aggregatedTaggables: Array<TagAggregatedTaggable>;
  relatedTags?: Maybe<Array<Tag>>;
  seo?: Maybe<Seo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TagAggregatedTaggable = {
  __typename?: 'TagAggregatedTaggable';
  type: TagItemType;
  count: Scalars['Int'];
};

export type TagItemCard = {
  __typename?: 'TagItemCard';
  id: Scalars['ID'];
  type: TagItemType;
  articleType?: Maybe<ArticleType>;
  title: Scalars['String'];
  slug: Scalars['String'];
  thumbnail?: Maybe<WediaImage>;
  brandName?: Maybe<Scalars['String']>;
  brandSlug?: Maybe<Scalars['String']>;
  recipeNutriscore?: Maybe<Scalars['String']>;
  recipePositiveFood?: Maybe<Scalars['Boolean']>;
  recipeCookingTime?: Maybe<Tag>;
  cheeseMilk?: Maybe<Tag>;
  cheeseRegion?: Maybe<Tag>;
};

export type TagItemCount = {
  __typename?: 'TagItemCount';
  type: TagItemType;
  count: Scalars['Int'];
};

export type TagItems = {
  __typename?: 'TagItems';
  paginatorInfo: PaginatorInfo;
  data: Array<TagItemCard>;
  count: Array<TagItemCount>;
};

export enum TagItemType {
  Article = 'ARTICLE',
  Cheese = 'CHEESE',
  CulinaryArticle = 'CULINARY_ARTICLE',
  CulinaryFolder = 'CULINARY_FOLDER',
  Plate = 'PLATE',
  Recipe = 'RECIPE'
}

export enum TagOrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** A paginated list of Tag items. */
export type TagPaginator = {
  __typename?: 'TagPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Tag items. */
  data: Array<Tag>;
};

export enum TagType {
  BalanceDiet = 'BALANCE_DIET',
  Budget = 'BUDGET',
  CheeseTheme = 'CHEESE_THEME',
  ConsumptionTime = 'CONSUMPTION_TIME',
  CookingTime = 'COOKING_TIME',
  Difficulty = 'DIFFICULTY',
  DishExample = 'DISH_EXAMPLE',
  Free = 'FREE',
  Health = 'HEALTH',
  HealthTheme = 'HEALTH_THEME',
  HealthType = 'HEALTH_TYPE',
  Intensity = 'INTENSITY',
  MainIngredient = 'MAIN_INGREDIENT',
  Milk = 'MILK',
  PreparationTime = 'PREPARATION_TIME',
  Region = 'REGION',
  Theme = 'THEME',
  Type = 'TYPE',
  Season = 'SEASON',
  FoodHabit = 'FOOD_HABIT'
}

export type TitleBodyBlock = BlockInterface & {
  __typename?: 'TitleBodyBlock';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type VideoHpBlock = {
  __typename?: 'VideoHpBlock';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  video?: Maybe<WediaVideo>;
  thumbnail?: Maybe<WediaImage>;
  ctaBlock?: Maybe<DefaultLink>;
};

export type VideosCardBlock = BlockInterface & {
  __typename?: 'VideosCardBlock';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  ctaBlock?: Maybe<DefaultLink>;
  videos?: Maybe<Array<WediaVideo>>;
  order: Scalars['Int'];
};

export type VideosHpBlock = BlockInterface & {
  __typename?: 'VideosHpBlock';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<VideoHpBlock>>;
  order: Scalars['Int'];
};

export type WediaImage = {
  __typename?: 'WediaImage';
  uuid: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['String']>;
  variations?: Maybe<Array<WediaImageVariation>>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type WediaImageVariation = {
  __typename?: 'WediaImageVariation';
  name: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  mimeType: Scalars['String'];
};

export type WediaVideo = {
  __typename?: 'WediaVideo';
  uuid: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  alt?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<WediaVideoSource>>;
  downloadUrl?: Maybe<Scalars['String']>;
};

export type WediaVideoSource = {
  __typename?: 'WediaVideoSource';
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  bitrate?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};
