import gql from 'graphql-tag'
import { Query } from 'src/graphql/generated/api-graphql'

import { SubNavColumn } from '../../../fragments/SubNavColumn'
import { WediaImageFragment } from '../../../fragments/WediaImageFragment'
import { ServiceQuery } from '../../../../redux/api/types/state'

const header: ServiceQuery = {
  query: gql`
    query header {
      configuration {
        header {
          items {
            href
            label
            columns {
              left {
                ...SubNavColumn
              }
              right {
                ...SubNavColumn
              }
            }
            mobileLinks {
              href
              label
              target
            }
            card {
              title
              subTitle
              wording
              href
              image {
                ...WediaImageFragment
              }
            }
          }
        }
      }
    }
    ${SubNavColumn}
    ${WediaImageFragment}
  `,
  transformer: (data: Query) => {
    return data?.configuration.header
  },
}

export default header
